import React from "react";

export function Who() {
  return (
    <section id="who" className="py-14 bg-neutral-light-grayish-blue lg:py-24">
      <div className="container text-center lg:text-left">
        <div className="grid mb-12 lg:mb-16">
          <div className="col-span-1">
            <h2 className="pb-5 text-3xl lg:text-4xl text-primary-dark-green">
              Wie?
            </h2>
            <h3 className="text-gray-800 font-bold mb-7">Rentattest partnernetwerk</h3>

            <p className="text-sm leading-5 text-neutral-grayish-blue lg:text-base mb-2">
              Rentattest is exclusief beschikbaar via BIV-erkende vastgoedmakelaars die officieel partner zijn van ons
              netwerk.
            </p>

            <p className="text-sm leading-5 text-neutral-grayish-blue lg:text-base mb-2">
              Het attest fungeert als een kwaliteitslabel dat garant staat voor objectiviteit, neutraliteit en respect
              voor privacy. Alle aangesloten makelaars hebben daartoe een Charter van Goed Gebruik ondertekend.
            </p>

            <p className="text-sm leading-5 text-neutral-grayish-blue lg:text-base mb-2">
              Dit waarborgt dat elke partner zich houdt aan de hoogste standaarden in de sector.
            </p>

            <h3 className="text-gray-800 font-bold mb-7 mt-7">Gerechtsdeurwaarder</h3>

            <p className="text-sm leading-5 text-neutral-grayish-blue lg:text-base mb-2">
              De gerechtsdeurwaarder is krachtens artikel 519, §2, 14° van het Gerechtelijk Wetboek bevoegd voor het
              uitvoeren van solvabiliteitsonderzoeken en voor het opstellen en afleveren van bijhorende rapporten.
            </p>

            <p className="text-sm leading-5 text-neutral-grayish-blue lg:text-base mb-2">
              Rentattest is een initiatief dat in samenwerking met gerechtsdeurwaarders instaat voor de analyse en
              aflevering van het gevraagde attest.
            </p>

            <p className="text-sm leading-5 text-neutral-grayish-blue lg:text-base mb-2">
              Wanneer de aanvraag wordt ontvangen zal een gerechtsdeurwaarder, volgens een geavanceerde en objectieve
              risicoanalyse, je solvabiliteit en betrouwbaarheid als huurder analyseren op basis van onder andere
              gegevens uit het rijksregister, Dienst voor Inschrijvingen Voertuigen (DIV), beslagberichten, ...
              aangevuld met eigen data.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
