import {Navbar} from "../components/Navbar";
import React from "react";
import Footer from "../components/Footer";

export function AlgemeneVoorwaarden() {
  return (
    <div className="flex flex-col justify-center min-h-screen mt-32">
      <Navbar/>
      <div className="container relative lg:block pb-10 policy">
        <h1>Algemene voorwaarden</h1>
        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Definities</h2>
          <ol className="list-decimal pl-5 space-y-2">
            <li><strong>"Rentattest.be"</strong> verwijst naar Copilr Studio bv, KBO nr. 0790.840.406, met
              maatschappelijke zetel Berthoutstraat 5, 2580 PUTTE
            </li>
            <li><strong>"Aanvrager"</strong> verwijst naar de persoon of personen wiens solvabiliteit is geanalyseerd en
              waarop het Attest betrekking heeft.
            </li>
            <li><strong>"Makelaar"</strong> verwijst naar de vastgoedmakelaar die als partner optreedt voor Rentattest
              en die de Aanvrager doorverwijst naar Rentattest middels het gebruik van een voucher om zich in te loggen.
            </li>
            <li><strong>"GDW"</strong> verwijst naar de door Rentattest aangestelde gerechtsdeurwaarder belast met het
              uitvoeren van de taken zoals omschreven in artikel 4 van de Algemene voorwaarden.
            </li>
            <li><strong>"Partijen"</strong> verwijst naar de Makelaar, Rentattest en de GDW</li>
            <li><strong>"Diensten"</strong> verwijst naar diensten die Rentattest aan haar Aanvrager levert, in het
              bijzonder het commercialiseren van een solvabiliteits- en betrouwbaarheidsanalyse en -attest voor huurders
              en verhuurders door middel van het inschakelen van een gerechtsdeurwaarder op basis van Artikel 519, §2,
              14° van het Gerechtelijk Wetboek.
            </li>
          </ol>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Toepassingsgebied</h2>
          <ol className="list-decimal pl-5 space-y-2">
            <li>Deze Algemene Voorwaarden zijn van toepassing op de Diensten die Rentattest aan haar Aanvrager ter
              beschikking stelt via de website www.Rentattest.be.
            </li>
            <li>De Aanvrager heeft door het starten van het proces op de website, uitdrukkelijk en expliciet deze
              Algemene Voorwaarden aanvaard en erkent ervan kennis genomen te hebben. De eventuele algemene voorwaarden
              van de Aanvrager zijn niet van toepassing op deze rechtsverhouding.
            </li>
            <li>Andere door Rentattest of één van Partijen, verstrekte diensten dan vermeld in deze Algemene Voorwaarden
              vallen niet onder de toepassing van deze Algemene Voorwaarden.
            </li>
            <li>Tenzij mits uitdrukkelijke schriftelijke toestemming van Rentattest, kan van de huidige Algemene
              Voorwaarden niet worden afgeweken.
            </li>
            <li>Indien enige bepaling van deze Algemene Voorwaarden nietig is, dan wel wordt vernietigd, dan blijven de
              overige bepalingen van deze Algemene Voorwaarden onverkort gelden.
            </li>
            <li>Rentattest behoudt zich het recht voor onderhavige Algemene Voorwaarden te allen tijde te wijzigen door
              een nieuwe versie op de website te publiceren. Elke bestelling die wordt geplaatst na de publicatie door
              Rentattest van de nieuwe versie van de Algemene Voorwaarden houdt ook de aanvaarding in van deze laatste
              versie.
            </li>
          </ol>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Doel van het Attest</h2>
          <ol className="list-decimal pl-5 space-y-2">
            <li>Het Attest biedt een indicatie van de solvabiliteit en betrouwbaarheid van de Aanvrager(s) op basis van
              beschikbare data en algoritmische analyse.
            </li>
            <li>Het Attest is bedoeld als een hulpmiddel voor verhuurders bij het nemen van beslissingen over het
              aangaan van een huurovereenkomst met de Aanvrager.
            </li>
            <li>Het Attest kan slechts één van de factoren zijn die de verhuurder in overweging neemt en vormt geen
              exclusieve basis voor beslissingen.
            </li>
            <li>Middels het aanvaarden van de Algemene Voorwaarden geeft de Aanvrager uitdrukkelijk mandaat aan
              Rentattest, om middels GDW een solvabiliteits- en betrouwbaarheidsanalyse en -attest conform 519, §2, 14°
              van het Gerechtelijk Wetboek te laten uitvoeren en te versturen naar betrokken Partijen
            </li>
            <li>De Aanvrager gaat er expliciet mee akkoord dat Rentattest de gegevens of informatie van de Aanvrager
              doorgeeft aan haar Partners in toepassing van de Algemene Voorwaarden.
            </li>
            <li>De Aanvrager is verantwoordelijk voor de inhoud van de door haar doorgegeven gegevens.</li>
          </ol>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Gebruik van het Attest</h2>
          <ol className="list-decimal pl-5 space-y-2">
            <li>Het Attest is uitsluitend bedoeld voor gebruik door de verhuurder in het kader van het aangaan van een
              huurovereenkomst met de Aanvrager.
            </li>
            <li>Het Attest mag niet worden gekopieerd, gewijzigd, verspreid of voor andere doeleinden gebruikt zonder de
              uitdrukkelijke schriftelijke toestemming van Rentattest.be.
            </li>
            <li>De Aanvrager heeft het recht om op elk moment schriftelijk te verzoeken om een toelichting of de
              achterliggende gegevens te bekomen, conform de toepasselijke wetgeving.
            </li>
          </ol>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Privacy en Gegevensbescherming GDPR</h2>
          <ol className="list-decimal pl-5 space-y-2">
            <li>Alle persoonlijke gegevens van de Aanvrager(s) worden verwerkt in overeenstemming met de geldende
              wetgeving inzake gegevensbescherming GDPR.
            </li>
            <li>De gegevens worden uitsluitend gebruikt voor het uitvoeren van de solvabiliteitsanalyse en het opstellen
              van het Attest, en worden niet gedeeld met derden, behalve wanneer dit noodzakelijk is voor de uitvoering
              van de dienst of wanneer wettelijk vereist.
            </li>
            <li>De Aanvrager heeft het recht op inzage, correctie, beperking, en verwijdering van zijn of haar
              persoonlijke gegevens zoals bepaald in de GDPR. Verzoeken hiertoe kunnen worden ingediend via
              info@rentattest.be.
            </li>
          </ol>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Beperking van Aansprakelijkheid</h2>
          <ol className="list-decimal pl-5 space-y-2">
            <li>Rentattest.be is niet aansprakelijk voor enige directe of indirecte schade, waaronder winstderving,
              verlies van inkomsten, of reputatieschade, die voortvloeit uit het gebruik van het Attest.
            </li>
            <li>De solvabiliteitsanalyse en het Attest zijn gebaseerd op de voor de gerechtsdeurwaarder beschikbare data
              op het moment van de analyse; Rentattest.be kan niet aansprakelijk worden gesteld voor de juistheid,
              volledigheid of actualiteit van deze data.
            </li>
            <li>Het Attest vormt geen garantie voor het toekomstige betalingsgedrag van de Aanvrager(s) en mag niet
              worden beschouwd als een definitieve uitspraak over hun financiële situatie.
            </li>
          </ol>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Middelenverbintenis</h2>
          <ol className="list-decimal pl-5 space-y-2">
            <li>Rentattest.be verbindt zich ertoe om naar beste kunnen en kennis de solvabiliteitsanalyse uit te voeren,
              maar geven geen enkele garantie met betrekking tot de nauwkeurigheid, volledigheid, of geschiktheid van
              het Attest voor een bepaald doel.
            </li>
            <li>Het Attest is het resultaat van een middelenverbintenis en niet van een resultaatsverbintenis.
              Rentattest.be kan niet aansprakelijk worden gesteld voor de gevolgen van beslissingen die op basis van het
              Attest worden genomen.
            </li>
          </ol>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Klachten en Beroep</h2>
          <ol className="list-decimal pl-5 space-y-2">
            <li>Aanvrager(s) die niet akkoord gaan met de Score van hun Attest kunnen binnen 10 dagen na ontvangst van
              het Attest een schriftelijke klacht indienen bij Rentattest.be.
            </li>
            <li>Klachten worden binnen 30 dagen na ontvangst behandeld, waarbij de Aanvrager een reactie zal ontvangen
              met betrekking tot de genomen acties of de motivatie van Rentattest.be indien de klacht ongegrond wordt
              verklaard.
            </li>
          </ol>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Inhoud website</h2>
          <ol className="list-decimal pl-5 space-y-2">
            <li>Rentattest besteedt de grootste zorg aan haar Website en het overzicht van de Diensten die daar worden
              aangeboden.
            </li>
            <li>Rentattest garandeert echter de juistheid van de informatie niet.</li>
            <li>De Aanvrager is eveneens bewust dat de voorgestelde informatie vatbaar is voor wijzigingen zonder
              voorafgaande bekendmaking. Rentattest is bijgevolg niet verantwoordelijk voor de inhoud van de Website of
              het gebruik ervan.
            </li>
            <li>Ingeval er links gecreëerd zouden worden met andere websites, bij voorbeeld met behulp van een
              zoekrobot, kan Rentattest niet verantwoordelijk gesteld worden voor het bestaan of de inhoud van deze
              websites.
            </li>
          </ol>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Intellectuele Rechten</h2>
          <ol className="list-decimal pl-5 space-y-2">
            <li>Alle rechten van intellectuele eigendom van de weergegeven informatie, tekens, mededelingen of andere
              uitingen met betrekking tot de diensten en/of met betrekking tot de internetsite zijn eigendom van
              Rentattest of aan haar gelieerde bedrijven.
            </li>
            <li>Onder intellectuele eigendomsrechten worden verstaan alle (a) auteursrechten, portretrechten, patenten,
              databankrechten en rechten met betrekking tot handelsmerken, ontwerpen, knowhow en bedrijfsgeheim (al dan
              niet geregistreerd); (b) aanvragen voor registratie, en het recht om een registratie aan te vragen, voor
              om het even welk van die rechten; en (c) alle andere intellectuele eigendomsrechten en equivalente of
              soortgelijke vormen van bescherming die om het even waar ter wereld bestaan.
            </li>
            <li>Elke kopie, publicatie, reproductie of andere exploitatie, ongeacht de wijze of de vorm ervan, van de
              website en weergegeven informatie, tekens, mededelingen of andere uitingen met betrekking tot de diensten
              is strikt verboden zonder de schriftelijke voorafgaandelijke toestemming van de Rentattest. De registratie
              en de import van de informatie van de website van Rentattest alsook van aanverwante diensten in een
              databank zijn uitdrukkelijk verboden. Een bepaald aantal gebruikte benamingen, tekens en logo's op deze
              site (onder andere de benaming Rentattest) zijn beschermde merken.
            </li>
          </ol>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Toepasselijke wetgeving – bevoegde rechtscolleges</h2>
          <ol className="list-decimal pl-5 space-y-2">
            <li>De Algemene Voorwaarden en, behoudens andersluidende overeenkomst, alle rechten en verplichtingen van de
              Aanvrager worden beheerst door het Belgisch recht.
            </li>
            <li>Behoudens afwijkende dwingende wettelijke bepalingen zijn de rechtbanken van de Ondernemingsrechtbank te
              Antwerpen, afdeling Antwerpen, bevoegd.
            </li>
          </ol>
        </section>
      </div>
      <Footer/>
    </div>
  );
}
