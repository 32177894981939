class FormDataResult {
  isOk: boolean;
  orderId: string | undefined;
  nrn: string | undefined;

  constructor(isOk: boolean, nrn?: string, orderId?: string) {
    this.isOk = isOk;
    this.nrn = nrn;
    this.orderId = orderId;
  }

  public static success(nrn: string, orderId: string) {
    return new FormDataResult(true, nrn, orderId)
  }

  public static failed() {
    return new FormDataResult(false)
  }
}

class RentattestFormObject {
  vouchercode: string = "";
  firstname: string = "";
  lastname: string = "";
  email: string = "";
  confirmEmail: string = "";
  monthlyAmount: number | undefined;
}

export interface IFormDataService {
  save(formData: RentattestFormObject): void

  load(): RentattestFormObject
}

export class SessionstorageFormDataService implements IFormDataService {
  KEY = "rentattest";

  load(): RentattestFormObject {
    let obj: RentattestFormObject = new RentattestFormObject();
    const item = sessionStorage.getItem(this.KEY);
    if (item && typeof item !== "undefined") {
      obj = JSON.parse(item);
    }
    return obj;
  }

  save(formData: RentattestFormObject): void {
    sessionStorage.setItem(this.KEY, JSON.stringify(formData))
    console.log(sessionStorage.getItem(this.KEY))
  }
}
