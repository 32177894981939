import React, {useEffect, useState} from "react";
import {Gdw} from "../components/Gdw";
import {FieldErrors, FieldValues, useForm, UseFormRegisterReturn} from "react-hook-form";
import {useLocation} from "react-router-dom";
import {FetchDataState} from "./FetchingNRNFailed";
import {IFetchNRN} from "../application/FetchNRN";
import {IFormDataService} from "../application/FormDataService";

interface OrderProps {
  fetchNrn: IFetchNRN,
  formdataService: IFormDataService
}

export function Order({fetchNrn, formdataService}: OrderProps) {
  const formData = formdataService.load();

  let urlSearchParams = new URLSearchParams(useLocation().search);
  const code = urlSearchParams.get("code");

  const {
    register,
    formState: {errors},
    handleSubmit, setValue
  } = useForm({mode: "onBlur"});

  // const onBeforeSubmit = (e: any) => {
  //   console.log("onBeforSubmit")
  //   console.log(orderFormState.vouchercode)
  //   console.log(formData)
  //   e.preventDefault();
  //   formdataService.save(orderFormState);
  //   handleSubmit(onSubmit)();
  // };

  const [state, setLoadingState] = useState(FetchDataState.LOADING)

  const [orderFormState, setOrderFormState] = useState(formData)
  // const [orderId  , setOrderId] = useState("");
  // const [nrn, setNrn] = useState("XX-XXXXX-XX");
  // const [vouchercode, setVouchercode] = useState(formData.vouchercode);
  // const [firstname, setFirstname] = useState(formData.firstname);
  // const [lastname, setLastname] = useState(formData.lastname);
  // const [email, setEmail] = useState(formData.email);
  // const [confirmEmail, setConfirmEmail] = useState(formData.confirmEmail);
  // const [monthlyAmount, setMonthlyAmount] = useState<number | undefined>(formData.monthlyAmount);

  useEffect(() => {
    (async () => {
      fetchNrn.fetch(code).then(result => {
        if (result.isOk) {
          // setNrn(nrn);
          // setOrderId(orderId);
          setLoadingState(FetchDataState.SUCCESSFUL);
        } else {
          setLoadingState(FetchDataState.FAILED);
        }
      })
    })();
  }, [code]);

  const onSubmit = (data:any) => {
    console.log(data)
    // (async () => {
    //   console.log("Submit")
    //   let body = {
    //     // orderId, firstname, lastname, email,
    //   };
    //   try {
    //     let url = `${config.apiBaseUrl}/payment`
    //     let result: Response = await fetch(url, {
    //       method: "POST",
    //       body: JSON.stringify(body),
    //       headers: {"Content-Type": "application/json"},
    //       redirect: 'follow'
    //     },)
    //     let json = await result.json();
    //     window.location = json.checkout_url
    //   } catch (e) {
    //     console.error(e);
    //   }
    // })();
  }

  return (
    <div
      className="bg-header-desktop bg-custom-header-size min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full mb-8">
        <div className="bg-white shadow-md rounded px-4 lg:px-8 pt-6 pb-8 mb-4 max-w-md mx-auto sm:max-w-xl">
          <div hidden={state !== FetchDataState.LOADING}>
            <p className="text-center my-4">
              Bezig met het ophalen van jouw rijksregisternummer
            </p>
            <p className="text-center my-4">
              een ogenblik geduld ...
            </p>
          </div>
          {/*<FetchingNRNFailed state={state}/>*/}
          <form
            hidden={state !== FetchDataState.SUCCESSFUL}
            onSubmit={handleSubmit(onSubmit)}>

            {/*<NRNField value={nrn}/>*/}

            <VoucherCodeField useFormRegisterReturn={register("vouchercode", {required: true})}
              // onChange={(e) => setFormState({...orderFormState, vouchercode: e.target.value})}
              // value={orderFormState.vouchercode}
                              errors={errors}
            />

            <FirstnameField useFormRegisterReturn={register("firstname", {required: true})}
                            onChange={(e) => setOrderFormState({...orderFormState, firstname: e.target.value})}
                            value={orderFormState.firstname}
                            errors={errors}
            />

            <LastnameField useFormRegisterReturn={register("lastname", {required: true})}
                           onChange={(e) => setOrderFormState({...orderFormState, lastname: e.target.value})}
                           value={orderFormState.lastname}
                           errors={errors}
            />

            <EmailField useFormRegisterReturn={register("email", {required: true})}
                        onChange={(e) => setOrderFormState({...orderFormState, email: e.target.value})}
                        value={orderFormState.email}
                        errors={errors}
                        label="Email"
            />

            <EmailField useFormRegisterReturn={register("confirmEmail", {required: true})}
                        onChange={(e) => setOrderFormState({...orderFormState, confirmEmail: e.target.value})}
                        value={orderFormState.confirmEmail}
                        errors={errors}
                        label="Bevestig Email"
            />

            <MonthlyAmountField useFormRegisterReturn={register("monthlyAmount", {required: true})}
                                onChange={(e) => setOrderFormState({...orderFormState, monthlyAmount: e.target.value})}
                                value={orderFormState.monthlyAmount}
                                errors={errors}
            />

            <SubmitButton/>
          </form>
        </div>
        <div className="text-center text-primary-sky-blue text-xs">
          <span>
            &copy;2021 <Gdw/>. Alle rechten voorbehouden
          </span>
          <span className="text-primary-dark mx-1">|</span>
          <a className="hover:text-primary-cadet-blue"
             href="/privacy-policy"
          > Privacy Policy
          </a>
          <span className="text-primary-dark mx-1">|</span>
          <a className="hover:text-primary-cadet-blue"
             href="/cookie-policy"
          > Cookie Policy
          </a>
        </div>
      </div>
    </div>

  );
}

function NRNField(props: { value: string }) {
  return <div className="mt-4">
    <label
      className="block text-gray-700 text-sm font-bold mb-2"
      htmlFor="name"
    >
      Rijksregisternummer
    </label>
    <input
      disabled
      type="text"
      value={props.value}
      className="bg-gray-300 focus:outline-none focus:shadow-outline border border-gray-300 rounded-lg py-2 px-4
                        my-4 block w-full appearance-none leading-normal disabled:opacity-50"
    />
  </div>;
}

function VoucherCodeField(props: {
  useFormRegisterReturn: UseFormRegisterReturn,
  // onChange: (e: any) => void,
  // value: string,
  errors: FieldErrors<FieldValues>
}) {
  return <div className="mt-4">
    <label
      className="block text-gray-700 text-sm font-bold mb-2"
      htmlFor="vouchercode"
    >
      Vouchercode
    </label>
    <input
      {...props.useFormRegisterReturn}
      className="bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded-lg py-2 px-4 block w-full appearance-none leading-normal"
      type="text"
      id="vouchercode"
      // name="vouchercode"
      // onChange={props.useFormRegisterReturn.onChange}
      // value={props.value}
      placeholder="Vouchercode"
    />
    {props.errors.vouchercode?.type === "required" &&
      <span className="text-red-400 text-sm">Vouchercode is verplicht</span>}
  </div>;
}

function FirstnameField(props: {
  useFormRegisterReturn: UseFormRegisterReturn,
  onChange: (e: any) => void,
  value: string,
  errors: FieldErrors<FieldValues>
}) {
  return <div className="mt-4">
    <label
      className="block text-gray-700 text-sm font-bold mb-2"
      htmlFor="firstname"
    >
      Voornaam
    </label>
    <input
      {...props.useFormRegisterReturn}
      className="bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded-lg py-2 px-4 block w-full appearance-none leading-normal"
      type="text"
      id="firstname"
      name="firstname"
      onChange={props.onChange}
      value={props.value}
      placeholder="Voornaam"
    />
    {props.errors.firstname?.type === "required" &&
      <span className="text-red-400 text-sm">Voornaam is verplicht</span>}
  </div>;
}

function LastnameField(props: {
  useFormRegisterReturn: UseFormRegisterReturn,
  onChange: (e: any) => void,
  value: string,
  errors: FieldErrors<FieldValues>
}) {
  return <div className="mt-4">
    <label
      className="block text-gray-700 text-sm font-bold mb-2"
      htmlFor="lastname"
    >
      Familienaam
    </label>
    <input
      {...props.useFormRegisterReturn}
      className="bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded-lg py-2 px-4 block w-full appearance-none leading-normal"
      type="text"
      id="lastname"
      name="lastname"
      onChange={props.onChange}
      value={props.value}
      placeholder="Familienaam"
    />
    {props.errors.lastname?.type === "required" &&
      <span className="text-red-400 text-sm">Familienaam is verplicht</span>}
  </div>;
}

function EmailField(props: {
  useFormRegisterReturn: UseFormRegisterReturn,
  onChange: (e: any) => void,
  value: string,
  errors: FieldErrors<FieldValues>,
  label?: string
}) {
  return <div className="mt-4">
    <label
      className="block text-gray-700 text-sm font-bold mb-2"
      htmlFor="email"
    >
      {props.label}
    </label>
    <input
      {...props.useFormRegisterReturn}
      className="bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded-lg py-2 px-4 block w-full appearance-none leading-normal"
      type="email"
      id="email"
      name="email"
      onChange={props.onChange}
      value={props.value}
      placeholder="jane@example.com"
    />
    {props.errors.email?.type === "required" &&
      <span className="text-red-400 text-sm">Email is verplicht</span>}
  </div>;
}

function MonthlyAmountField(props: {
  useFormRegisterReturn: UseFormRegisterReturn,
  onChange: (e: any) => void,
  value: number | undefined,
  errors: FieldErrors<FieldValues>
}) {
  return <div className="mt-4">
    <label
      className="block text-gray-700 text-sm font-bold mb-2"
      htmlFor="monthylAmount"
    >
      Maandelijks bedrag
    </label>
    <input
      {...props.useFormRegisterReturn}
      className="bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded-lg py-2 px-4 block w-full appearance-none leading-normal"
      type="number"
      id="monthylAmount"
      name="monthylAmount"
      onChange={props.onChange}
      value={props.value}
      placeholder="€500"
    />
    {props.errors.monthlyAmount?.type === "required" &&
      <span className="text-red-400 text-sm">Maandelijks bedrag is verplicht</span>}
  </div>;
}

function SubmitButton() {
  return <button type="submit"
                 className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm
                    font-medium rounded-md text-white bg-primary-cadet-blue hover:bg-primary-sky-blue focus:outline-none focus:ring-2
                    focus:ring-offset-2 focus:ring-primary-sky-blue mt-4">
							<span className="absolute left-0 inset-y-0 flex items-center pl-3">
                <svg className="h-5 w-5 text-primary-sky-blue group-hover:text-primary-cadet-blue"
                     xmlns="http://www.w3.org/2000/svg"
                     viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
									<path fillRule="evenodd"
                        d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                        clipRule="evenodd"/>
								</svg>
							</span>
    Bevestig met Itsme
  </button>;
}
