import React from "react";
import {VraagSolvabiliteitsAttestAanButton} from "./VraagSolvabiliteitsAttestAanButton";


export function Hero() {
  return (
    <section id="hero" className="relative">
      <Background/>
      <div className="flex items-center container relative z-20 min-h-screen lg:h-screen lg:block">
        <div className="flex flex-col justify-center h-full lg:flex-row lg:justify-between gap-8">
          <div
            className="flex flex-col order-2 justify-center pb-4 h-full lg:pb-0 lg:w-96 lg:justify-center lg:order-none">
            <Body></Body>
          </div>
          <div className="flex flex-1 order-1 items-center justify-center lg:order-none lg:h-auto lg:mt-0 mt-32">
            <img className="w-8/12" src="/rentattest-hero-image.png"
                 alt="rentattest.be eerlijke screening, zorgeloos verhuren"/>
          </div>
        </div>
      </div>
    </section>
  );
}


function Body() {
  return (
    <div
      className="flex flex-col justify-center items-center h-1/2 text-center lg:items-start lg:text-left">
      <h1 className="mb-7 leading-5 text-primary-dark">
        Maak Huren en Verhuren Eenvoudig en Zorgeloos
      </h1>
      <p className="mb-7 text-xs leading-5 text-primary-dark lg:text-neutral-grayish-blue lg:text-base">
        Met een objectief solvabiliteits- en betrouwbaarheidsrapport van Rentattest, vergroot je als huurder je kansen
        op een woning en maak je als verhuurder een snelle, weloverwogen keuze.
      </p>
      <p className="mb-7 text-xs leading-5 text-primary-dark lg:text-neutral-grayish-blue lg:text-base">
        Vraag hier je Rentattest aan met de inlogcode die je van je vastgoedmakelaar hebt ontvangen en geniet van een
        zorgeloos huurproces.
      </p>
      <VraagSolvabiliteitsAttestAanButton/>
      <p className="mt-7 mb-7 text-xs leading-5 text-primary-dark lg:text-neutral-grayish-blue lg:text-base">
        Geen inlogcode?
      </p>
      <p className="mb-7 text-xs leading-5 text-primary-dark lg:text-neutral-grayish-blue lg:text-base">
        <a href="mailto:info@rentattest.be" className="mr-1">
          Contacteer ons
        </a>
        <span>
          we verwijzen je graag door naar een partner-vastgoedmakelaar van Rentattest.
        </span>
      </p>
    </div>
  )
}

function Background() {
  return (
    <div
      className="absolute w-full min-h-full lg:h-full bg-left bg-no-repeat bg-header-desktop bg-custom-header-size lg:block"/>
  )
}

